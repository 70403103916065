import { useContext, useEffect } from "react";
import Animation from "../../../../../components/Components/lotties/waitingSignature.json";
import Lottie from "lottie-react";
import FadeIn from "react-fade-in";
import secure from "./../../../../../assets/img/secure.svg";
import "./../css/waitingSignature.scss";
import StarIcon from "@material-ui/icons/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import { I18nContext } from "../../../../../context/i18n.context";
import { ButtonDS } from "get-life-storybook-ts";
import { useQuery } from "../../../../../context/GetLifeContext.context";
import HttpLeadRepository from "../../../../../api/request/Lead/Lead.service";

export default function RenewalConfirmation() {
  let query = useQuery();
  const leadId = query.get("leadId");
  const token = query.get("token");
  const year = query.get("year");

  const rateUs = () => {
    window.open(translate("trustpilot.link"), "_blank");
  };

  const {
    state: { translate },
  } = useContext(I18nContext);

  const leadRepository =  new HttpLeadRepository();



  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (leadId && token && year) {
      leadRepository.postConfirmRenewalAgl(leadId, parseInt(token), parseInt(year));
    }
  }, []);

  return (

    <div className={"waitingSignature-outerContainer"}>
      <FadeIn>
        <div className={"waitingSignature-container"}>
          <section className="waitingSignature-topContainer">
            <div className="waitingSignature-topContent">
              <Lottie
                className="waitingSignature-animation"
                animationData={Animation}
                loop={false}
              />
              <p className="waitingSignature-congrats">
                {translate("renewalConfirmation.congratulations")}
              </p>
              <p className="waitingSignature-congratsBold">{translate("renewalConfirmation.superTitle1")}</p>
              <p style={{ paddingTop: "29px" }} className="waitingSignature-p">
                {translate("renewalConfirmation.titleText1")}
              </p>
              <p className="waitingSignature-p">{translate("renewalConfirmation.subtitleText1")}</p>

            </div>
          </section>

          <section className="waitingSignature-botomContainer">
            <article className="waitingSignature-boxContainer">
              <div className="waitingSignature-boxContent">
               
                  <>
                    <p className="waitingSignature-box-title">
                      <p>{translate("renewalConfirmation.experience")} </p>
                      <div className="waitingSignature-starsContent">
                        <StarIcon
                          fontSize={"small"}
                          className={"waitingSignature_stars_icon_active"}
                        />
                        <StarIcon
                          fontSize={"small"}
                          className={"waitingSignature_stars_icon_active"}
                        />
                        <StarIcon
                          fontSize={"small"}
                          className={"waitingSignature_stars_icon_active"}
                        />
                        <StarIcon
                          fontSize={"small"}
                          className={"waitingSignature_stars_icon_active"}
                        />
                        <StarHalfIcon
                          fontSize={"small"}
                          className={"waitingSignature_stars_icon_active"}
                        />
                        ?
                      </div>
                    </p>
                    <p className="waitingSignature-box-title">
                      {translate("renewalConfirmation.comment")}
                    </p>
                    <p className="waitingSignature-trusPilotLabel">
                      {translate("renewalConfirmation.trustpilot")}
                    </p>
                    <div className="waitingSignature-starBox">
                      <div className="waitingSignature-TruststarContainer">
                        <p className="waitingSignature-points">4.6</p>
                        <div className="waitingSignature-starsContent">
                          <StarIcon
                            fontSize={"small"}
                            className={"waitingSignature_stars_icon_active"}
                          />
                          <StarIcon
                            fontSize={"small"}
                            className={"waitingSignature_stars_icon_active"}
                          />
                          <StarIcon
                            fontSize={"small"}
                            className={"waitingSignature_stars_icon_active"}
                          />
                          <StarIcon
                            fontSize={"small"}
                            className={"waitingSignature_stars_icon_active"}
                          />
                          <StarHalfIcon
                            fontSize={"small"}
                            className={"waitingSignature_stars_icon_active"}
                          />
                        </div>
                      </div>
                      <div className="waitingSignature-starButtonContainer">
                        <ButtonDS
                          onClick={rateUs}
                          label={translate("renewalConfirmation.btnComment")}
                        />
                      </div>
                    </div>
                  </>
              </div>
            </article>
          </section>
          <div className="waitingSignature-footerContainer">
            <img src={secure} alt="aplicación segura" />
            <p className="waitingSignature-footer">
              {translate("paymentFooter.text")}
            </p>
          </div>
        </div>
      </FadeIn>
    </div>
  );
}
