import * as React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate } from "react-router";
import HttpLeadRepository from "../../../../api/request/Lead/Lead.service";
import HttpPaymentRepository from "../../../../api/request/Payment/Payment.service";
import { GetlifeContext } from "../../../../context/GetLifeContext.context";
import { I18nContext } from "../../../../context/i18n.context";
import { StripeIntentStatusI } from "../dashboard/Policy/ModalPolicy/ModalPolicy.controller";
import { useDevice } from "get-life-storybook-ts";
import { DOMAIN } from "../../../../constants/Global";
import { NewCheckoutI, PurchasePeriodT } from "../../../../api/request/Lead/Interfaces/DataInterface.response";
import HttpCouponsRepository from "../../../../api/request/Coupons/Coupons.service";
import { GetLifeTrackerContext } from "../../../../context/GetLifeTracker.context";
import posthog from 'posthog-js'

declare global {
  interface Window {
    gtag?: any;
    ttq?: any;
  }
}

export type ValidateCouponT = "validate" | "invalidate" | "error" | "validate-null";

export interface CouponInfoI {
  conditions?: string;
  exceptions?: string;
  error?: string;
  code?: string;
}

const PaymentPageController = () => {
  const { setBackFlag, leadId, token, setLoading, loading, handleCallMe, domain } =
  React.useContext(GetlifeContext);

  const navigate = useNavigate();
  const location = useLocation();
  const paymentLocation = location?.pathname.split("/")[1];
  const { isMobile } = useDevice();
  const [payConfirm, setPayConfirm] = React.useState(false);

  const [payConfirm2, setPayConfirm2] = React.useState(false);

  const [isUserDataAvailable, setIsUserDataAvailable] = React.useState(false);
  const [buttonContent, setButtonContent] = React.useState<any>("Hacer pago");

  const [openBox, setOpenBox] = React.useState({
    userData: false,
    creditCardData: false,
  });

  const [checkout, setCheckout] = React.useState<NewCheckoutI>();

  const [couponInfo, setCouponInfo] = React.useState<CouponInfoI>();

  const [purchasePeriod, setPurchasePeriod] =
    React.useState<PurchasePeriodT>((domain === "es" || domain === "wl_es") && posthog.getFeatureFlag('Payment_page_periodicity_AB_test')  == "test" ? "yearly" : "monthly");

  const [validateCoupon, setValidateCoupon] = React.useState<ValidateCouponT>();

  const [setupIntent, setSetupIntent] = React.useState<any>([]);

  const nodeConditionsModal: any = React.useRef(null);

  const handleConditions = () => nodeConditionsModal.current?.handleShowPortal();

  const [capitals, setCapitals] = React.useState({});
  const [prices, setPrices] = React.useState({});
  const [promotion, setPromotion] = React.useState({});

  const [stripeInputStatus, setStripeInputStatus] =
    React.useState<StripeIntentStatusI>({
      complete: false,
      error: undefined,
    });

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const { handleTrackerQuestion } = React.useContext(GetLifeTrackerContext);

  const leadRepository = new HttpLeadRepository(leadId, token);
  const paymentRepository = new HttpPaymentRepository(leadId, token);
  const couponsRepository = new HttpCouponsRepository(leadId, token);

  const handleIsUserDataAvailable = () => {
    setIsUserDataAvailable(true);
  };

  const handleOpenBox = (index: number | string, value: any) => {
    const prevValue = openBox;
    setOpenBox({ ...prevValue, [index]: value });
  };

  const goToUpsell = () => {
    setBackFlag(true);
    navigate(`/${paymentLocation}/1`);
  };

  const closeNameForm = () => handleOpenBox("userData", false);

  // TODO:
  const handleStripeStatus = (
    index: any,
    value: any,
    index2: any,
    value2: any
  ) => {
    const prevValue = stripeInputStatus;

    setStripeInputStatus({
      ...prevValue,
      [index]: value,
      [index2]: value2,
    });
  };

  // TODO:
  const handleIsLoading = (value: any) => {
    if (value === undefined) {
      setLoading(!loading);
    } else {
      setLoading(value);
    }
  };

  const handleCheckbox = () => setPayConfirm(!payConfirm);

  const handleCheckbox2 = () => setPayConfirm2(!payConfirm2);

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || "",
    {
      locale: "es",
      stripeAccount: process.env.REACT_APP_STRIPE_ACCOUNT,
    }
  );

  const isButtonDisabled = () => {
    if (DOMAIN === "it") {
      if (
        stripeInputStatus.complete === true &&
        stripeInputStatus.error === undefined &&
        payConfirm === true && payConfirm2 === true
      ) {
        return false;
      }
      return true;
    }

    if (
      stripeInputStatus.complete === true &&
      stripeInputStatus.error === undefined &&
      payConfirm === true
    ) {
      return false;
    }
    return true;

  };

  const navigateTo = () => {
    navigate("/confirmation");
  };

  const getCheckoutData = async () => {
    await leadRepository.getPaymentData().then((response: NewCheckoutI) => {
      setCheckout(response);
      setCouponInfo(
        response.promoConditions
          ? {
            conditions: response.promoConditions,
            exceptions: "",
            code: response.promotionalCode,
          }
          : undefined
      );
      if (response.isPromoted) {
        setValidateCoupon("validate")
      }
    });
  }

  const getInfoSQL = async () => {
    // Llamada a pricing-capitals
    await leadRepository.getPricingCapitals().then((response: any) => {
      setCapitals(response.capitals)
      setPrices(response.prices);
      setPromotion(response.promotion);
    });

  }

  React.useEffect(() => {
    (async () => {
      if (DOMAIN === "sql_es") {
        getInfoSQL()
      }
      await getCheckoutData();
    })()
  }, [validateCoupon, purchasePeriod]);

  React.useEffect(() => {
    (async () => {
      if (domain === "es" || domain === "wl_es") {
        // Otherwise, you can just do:
        console.log("AB", posthog.getFeatureFlag('Payment_page_periodicity_AB_test'))
        if (posthog.getFeatureFlag('Payment_page_periodicity_AB_test')  == "test") {
            leadRepository.assingABTest(3);
            // do something
            handlePurchasePeriod("yearly")
        }
        if (posthog.getFeatureFlag('Payment_page_periodicity_AB_test')  == "control") {
          leadRepository.assingABTest(2);
        }
        }
    })()
  }, [posthog.getFeatureFlag('Payment_page_periodicity_AB_test')]);

  React.useEffect(() => {
    isButtonDisabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeInputStatus]);

  React.useEffect(() => {
    if (checkout) {
      if (checkout?.name === null || checkout?.lastName === null) {
        handleOpenBox("userData", true);
      } else {
        setIsUserDataAvailable(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout]);

  React.useEffect(() => {
    if (isUserDataAvailable) {
      setLoading(true);
      if (checkout?.period) {
        handlePurchasePeriod(checkout?.period.toLowerCase());
      }
      paymentRepository.setup().then((res) => {
        const data = res;
        setSetupIntent(data);
        setLoading(false);
      });
      setOpenBox({ userData: false, creditCardData: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserDataAvailable]);

  React.useEffect(() => {
    if (!loading) {
      setButtonContent("Reintentar");
    }
  }, [loading]);

  const saveData = (data: any) => {
    leadRepository.saveData(data).then((res) => {
      getCheckoutData();
      handleIsUserDataAvailable();
    });
  };

  const handlePurchasePeriod = async (value: any) => {
    await leadRepository.updatePeriod(value);
    setPurchasePeriod(value);
  };

  const handleRemoveCoupon = async (code: string) => {
    try {
      await couponsRepository.removeCoupon({
        promotionalCode: code,
      });
      setValidateCoupon(undefined);
      setCouponInfo(undefined);
    } catch (error) { }
  };

  const fetchCheckPromo = async (
    code: string,
    yearlyPrice: number,
    func?: (code: string) => void
  ) => {
    const response = await couponsRepository.checkPromotionalCode({
      promotionalCode: code,
      yearlyPrice: yearlyPrice,
    });
    if (response.is_applicable_promo) {
      func && (await func(code));
      setValidateCoupon("validate");
      setCouponInfo({
        conditions: response.promo_conditions,
        exceptions: response.promo_exceptions,
        code: response.promo,
      });
    } else {
      setValidateCoupon("invalidate");
    }
  };

  const addedCoupon = async (code: string) => {
    try {
      await couponsRepository.applyCoupon({ promotionalCode: code });
      handleTrackerQuestion({
        type: "FS",
        event: `appliedCoupon`,
        data: {
          promotionalCode: code,
          leadId: leadId,
          market: domain
        },
      });
    } catch (error) {
      setValidateCoupon("invalidate");
     }
  };

  const handleAddCoupon = async (code: string, yearlyPrice: number) => {
    try {
      await fetchCheckPromo(code, yearlyPrice, addedCoupon);
    } catch (error) { }
  };

  return {
    buttonContent,
    closeNameForm,
    goToUpsell,
    handleCheckbox,
    handleCheckbox2,
    handleIsLoading,
    handleOpenBox,
    handleStripeStatus,
    isButtonDisabled,
    isMobile,
    loading,
    isUserDataAvailable,
    leadId,
    navigateTo,
    openBox,
    payConfirm,
    payConfirm2,
    saveData,
    handleCallMe,
    stripePromise,
    stripeInputStatus,
    token,
    translate,
    checkout,
    nodeConditionsModal,
    handleConditions,
    couponInfo,
    validateCoupon,
    handlePurchasePeriod,
    handleRemoveCoupon,
    setupIntent,
    handleAddCoupon,
    capitals,
    prices,
    promotion
  };
};

export default PaymentPageController;
