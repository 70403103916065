export const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];

export const billingType = [
    { value: "monthly", text: "Mensual" },
    { value: "yearly", text: "Anual" },
];

export const LOGIN_STATUS_SIGNATUREPENDING = "signature_pending";
export const LOGIN_STATUS_COMPLETE = "complete";
export const LOGIN_STATUS_OPS_REVIEW = "ops_review";
export const LOGIN_STATUS_IT_REVIEW = "it_review";
export const LOGIN_STATUS_INSURER_REVIEW = "insurer_review";
export const LOGIN_STATUS_INITIAL_STATUS = "initial_status";
export const LOGIN_STATUS_REJECTED = "rejected";
export const LOGIN_STATUS_CANCELED = "canceled";