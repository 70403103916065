import { UserStatusT } from "../context/GetLifeContext.context";

const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

const USER_STATUS: Record<UserStatusT, string[]> = {
  product_questions_pending: [
    "/productQuestions",
    "/productQuestionsChallenger",
  ],
  questions_pending: ["/step/4", "/stepChallenger/4"],
  rejected: ["/notcoverage"],
  canceled: ["/notcoverage"],
  nationality_pending: ["/coverage/0"],
  upsell_pending: ["/payment/1"],
  payment_pending: ["/payment/3"],
  paymentmethodpending_pending: ["/payment/3"],
  address_pending: ["/coverage/1"],
  beneficiaries_pending: [DOMAIN === "it" ? "/beneficiary" : "/coverage/2"],
  idcard_pending: ["/coverage/7"],
  beneficiariestypes_pending: ["/beneficiaries/1"],
  physicalperson_pending: ["/beneficiaries/2"],
  bankingentity_pending: ["/beneficiaries/3"],
  legalperson_pending: ["/beneficiaries/4"],
  legalnote_pending: ["/payment/4"],
  signature_pending: ["/payment/5", "/waitingsignature"],
  payment_validation_pending: ["/payment/5", "/waitingsignature"],
  complete: ["/dashboard/global"],
  ops_review: ["/dashboard/global"],
  it_review: ["/dashboard/global"],
  insurer_review: ["/dashboard/global"],
  claim: ["/dashboard/global"],
  docs_pending: ["/dashboard/global"],
  csv_pending: ["/dashboard/global"],
  validation_pending: ["/waitingvalidation"],
  initial_status: ["/login"],
  prestack_pending: ["/productQuestions"],
  telesubscription: [""],
};

export const PRE_PAYMENT_STATUS: any = [
  "product_questions_pending",
  "questions_pending",
  "upsell_pending",
  "payment_pending",
  "paymentmethodpending_pending",
  "initial_status",
  "prestack_pending",
];

export default USER_STATUS;
